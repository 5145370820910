<template>
  <div class="container">
    <loading v-if="loading" />
    <div v-if="clientForm">
      <div class="row">
        <div class="col my-auto">
          <h5>{{ clientForm.title }}</h5>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <p class="alert alert-primary mb-3 mt-1">
            Please complete the following form sections. Your changes are saved
            automatically, so you can pause and come back at any time.
          </p>
        </div>
        <div class="col-12 text-right">
          <button
            class="btn bg-success mt-1 mb-2 mr-2 text-white align-center"
            @click="saveProgress()"
          >
            Save Progress
          </button>
          <button
            class="btn btn-primary mt-1 mb-2 "
            @click="sendResponse"
            :disabled="clientForm.status !== 'in_progress' || busySending"
          >
            <i class="fad fa-share mr-1" v-if="!busySending"></i>
            <i class="fad fa-spinner fa-spin mr-1" v-else></i>
            Submit & Complete
          </button>
        </div>
      </div>

      <div class="row mb-2" v-if="clientForm.status === 'response_received'">
        <div class="col text-center">
          Response Sent: {{ this.clientForm.client_response_date | formatDate }}
        </div>
      </div>

      <div class="row mb-4">
        <div class="col">
          <div class="accordion" id="sectionsAccordion">
            <div v-for="section in clientForm.sections" class="shadow">
              <div class="card sticky-top fixed-top shadow-none">
                <div class="card-header pl-1 pr-3" :id="'heading' + section.id">
                  <h2 class="mb-0">
                    <button
                      class="btn btn-link font-weight-bold w-75 text-left"
                      type="button"
                      data-toggle="collapse"
                      :data-target="'#collapse' + section.id"
                      aria-expanded="false"
                      :aria-controls="'collapse' + section.id"
                      @click="scrollTo('collapse' + section.id)"
                    >
                      <i class="fad fa-caret-down mr-2"></i>
                      {{ section.title }}
                    </button>
                    <span class="small float-right">
                      <small>({{ getNumberOfQs(section) }})</small>
                    </span>
                  </h2>
                </div>
              </div>
              <div class="card">
                <div
                  :id="'collapse' + section.id"
                  class="collapse"
                  :aria-labelledby="'heading' + section.id"
                  data-parent="#sectionsAccordion"
                >
                  <div class="card-body" :ref="'body-' + section.id">
                    <form
                      :id="'form-render-' + section.id"
                      @change="saveResponse(section)"
                      @submit.prevent="saveResponse(section)"
                    ></form>
                    <div v-if="section.scored">
                      <h5 class="font-weight-bold">
                        Section Score Total: {{ section.score }}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "@/views/components/loaders/Loading";
require("jquery-ui-bundle");
require("formBuilder/dist/form-render.min.js");
export default {
  components: {Loading},
  props: ["id"],
  data() {
    return {
      loading: true,
      busySending: false,
      clientForm: null,
      sectionRenderers: [],
      unsavedChanges: false,
    };
  },
  computed: {
    client() {
      return this.$store.user;
    },
  },
  methods: {
    fetchClientForm() {
      this.$axios
        .get(
          process.env.VUE_APP_API_URL + "/client/forms/" + this.$route.params.id
        )
        .then(({ data }) => {
          this.clientForm = data;

          for (let i = this.clientForm.sections.length - 1; i >= 0; i--) {
            const section = this.clientForm.sections[i];

            setTimeout(() => {
              var formRenderOptions = {
                dataType: "json",
                formData: section.client_response,
              };

              this.sectionRenderers.push({
                section_id: section.id,
                renderer: $("#form-render-" + section.id).formRender(
                  formRenderOptions
                ),
              });
            }, 500);
          }

          this.loading = false;
        });
    },
    saveProgress() {
      this.clientForm.sections.forEach((s) => this.saveResponse(s));
    },
    saveResponse(section) {
      this.unsavedChanges = true;
      if (this.clientForm.status !== "response_received") {
        const renderer = this.sectionRenderers.filter((renderer) => {
          return renderer.section_id === section.id;
        })[0].renderer;

        if (section.scored) {
          var score = 0;
          renderer.userData.forEach((field) => {
            if (field.type === "number") {
              if (field.userData && parseInt(field.userData[0])) {
                score = score + parseInt(field.userData[0]);
              }
            }
            if (field.type === "radio-group") {
              if (field.userData && parseInt(field.userData[0])) {
                score = score + parseInt(field.userData[0]);
              }
            }
            if (field.type === "checkbox-group") {
              if (field.userData) {
                for(let i = 0; i < field.userData.length; i++) {
                  if(parseInt(field.userData[i])) {
                    score = score + parseInt(field.userData[i]);
                  }
                }
              }
            }
          });
          section.score = score;
        }

        this.$axios
          .post(process.env.VUE_APP_API_URL + "/client/forms/save-response", {
            client_form_section_id: section.id,
            form_data: renderer.userData,
            score: section.score,
          })
          .then(({ data }) => {
            if (this.clientForm.status === "sent") {
              this.clientForm.status = "in_progress";
            }
            this.$EventBus.$emit("alert", data);
            this.unsavedChanges = false;
          })
          .catch((error) => {
            this.unsavedChanges = true;
            this.$EventBus.$emit("alert", {
              color: "danger",
              message: "Error. Unable to save changes.",
            });
          });
      } else {
        this.$EventBus.$emit("alert", {
          color: "danger",
          message: "Response already sent. Unable to save changes.",
        });
      }
    },
    checkFields() {
      let unfilledItems = 0;
      this.clientForm.sections.forEach((section) => {
        let reqItems = document.getElementById('form-render-' + section.id).querySelectorAll("[required]");
        reqItems.forEach((item) => {
          if(item.value === "") {
            unfilledItems += 1;
          }
        })
      })
      return unfilledItems;
    },
    sendResponse() {
      if(this.checkFields() > 0) {
        alert("You have missed some required fields");
        return;
      }

      var confirmed = confirm(
        "Your response will be sent to your practitioner. You will not be able to modify your response after submission. Continue?"
      );
      if (confirmed) {
        this.busySending = true;
        this.$axios
          .post(process.env.VUE_APP_API_URL + "/client/forms/send-response", {
            client_form_id: this.clientForm.id,
          })
          .then(({ data }) => {
            this.$EventBus.$emit("alert", data);
            this.clientForm.status = "response_received";
            this.clientForm.client_response_date = moment.now();
            const vm = this;
            setTimeout(() => {
              vm.$router.push("/dashboard");
            }, 500);
            this.busySending = false;
          });
      }
    },
    getNumberOfQs(section) {
      var count = 0;

      const options = JSON.parse(section.form_options);
      if (options) {
        for (let i = 0; i < options.length; i++) {
          if (options[i].type !== "header" && options[i].type !== "paragraph") {
            count++;
          }
        }
      }
      return count;
    },
    scrollTo(elementId) {
      let vm = this;
      let container;
      setTimeout(() => {
        container = vm.$el.querySelector("#" + elementId);
        if (container) {
          scrollTo(0, 0);
          setTimeout(() => {
            //container.scrollIntoView();
            const y = container.getBoundingClientRect().top - 50;
            scrollTo({ top: y, behavior: "smooth" });
          }, 50);
        }
      }, 300);
    },
  },
  mounted() {
    this.fetchClientForm();

    window.addEventListener("beforeunload", (event) => {
      if (this.unsavedChanges) {
        event.returnValue = `There are unsaved changes, are you sure you want to leave?`;
      }
    });
  },
  filters: {
    formatDate(date) {
      return moment(date).format("LL");
    },
  },
};
</script>

<style>
.requiredItem {
  background-color: red;
  border: 2px;
}
</style>
